import React from 'react';
import { graphql } from 'gatsby';
import VehicleLayout from '../layouts/vehicle';
import '../fragments/vehicleGatsby';
import '../fragments/vehiclePreviewGatsby';

export const query = graphql`
  query SingleVehicleQuery($id: String!, $relatedVehiclesFilter: StrapiVehiclesVehiclesFilterInput, $withRelatedVehicles: Boolean!) {
    head: strapiVehiclesVehicles(id: { eq: $id }) {
      id
      ...VehicleHead
    }

    vehicle: strapiVehiclesVehicles(id: { eq: $id }) {
      ...Vehicle
    }

    specs: strapiVehiclesVehicles(id: { eq: $id }) {
      ...VehicleSpecs
    }

    relatedVehicles: allStrapiVehiclesVehicles(limit: 4, filter: $relatedVehiclesFilter) @include(if: $withRelatedVehicles) {
      nodes {
        id
        ...VehiclePreview
      }
    }
  }
`;

const VehicleTemplate = ({ data, pageContext: { slug, createdBy } }) => (
  <VehicleLayout
    {...data}
    relatedVehicles={data.relatedVehicles?.nodes}
    slug={slug}
    createdBy={createdBy}
  />
);

export default VehicleTemplate;
